<template>
  <div>
    <Header v-if="!isLoginPage"/>
    <!-- 背景图加给main盒子 -->
    <div class="main">
      <router-view/>
    <Footer v-if="!isLoginPage"/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data(){
    return {
      isLoginPage: false
    }
  },
  watch: {
    $route(to) {
      this.isLoginPage = to.path === '/login';
    }
  },
  created() {
    this.isLoginPage = this.$route.path === '/login';
  }
}
</script>
<style scoped>
.main{
  background: url('../src/assets/images/bgsevers.png');
    background-repeat: repeat;
    background-position: 50%;
    background-size: contain;
}

</style>