<template>
  <div class="footer-container">
    <div class="link">
      <div style="cursor: pointer;" @click="toInfo('/pc/info?type=map')">网站地图</div>
      <div style="cursor: pointer;" @click="toInfo('/pc/info?type=contact')">联系我们</div>
      <div style="cursor: pointer;" @click="toInfo('/pc/info?type=copyright')">版权声明</div>
    </div>
    <div class="copyright" style="cursor: pointer">
      <a class="href" href="https://beian.miit.gov.cn" target="_blank">{{ info?.ba_info }}</a>
    </div>
    <div class="qrcode-box">
      <div class="qrcode-content">
        <div style="width: 100px;height: 100px;">
          <img :src="info?.m_qrcode" alt="">
        </div>
        <div class="qrcode-text">手机版访问</div>
      </div>
      <div class="qrcode-content">
        <div style="width: 100px;height: 100px;">
          <img :src="info?.mini_qrcode" alt="">
        </div>
        <div class="qrcode-text">微信扫码访问</div>
      </div>
    </div>
  </div>
</template>

<script>
import {get} from "@/utils/request";

export default {
  name: 'Footer',
  created() {
    this.getSystem()
  },
  data() {
    return {
      info: {}
    }
  },
  methods: {
    getSystem() {
      get('/getSystem', {fields: 'ba_info,m_qrcode,mini_qrcode'}).then(res => {
        this.info = res.data
      }).catch(() => {
      })
    },
    toInfo(url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.footer-container {
  color: #B5AAA5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 65px;

  .link {
    display: flex;
    justify-content: space-around;
    width: 12%;
  }

  .copyright {
    margin-top: 20px;
  }

  .href {
    font-size: 12px;
    color: #B5AAA5;
  }

  .qrcode-box {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 20%;

    .qrcode-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .qrcode-text {
        margin-top: 2px;
      }
    }
  }
}
img {
  width: 100%;
  height: 100%;
}
</style>
