<template>
  <div class="top">
    <div class="co">
      <img style="width: 60px" src="@/assets/imgs/01/logo.png" alt="Logo"/>
      <div style="margin-left: 30px; color: #cbb089;">
        <p class="top-p">{{ mainTitle }}</p>
        <p class="top-p">{{ subTitle }}</p>
      </div>
      <nav>
        <ul>
          <li v-for="item in list" :key="item.name" @mouseenter="handleMouseEnter(item)" @mouseleave="handleMouseLeave">
            <router-link v-if="item.path" :to="item.path">{{ item.name }}</router-link>
            <span v-else @click="handleClick(item)">{{ item.name }}</span>
          </li>
        </ul>
      </nav>
      <img @click="$router.push({ path: '/pc/search' })" style="width: 27px; cursor: pointer; margin-left: auto"
           src="@/assets/imgs/01/search.png" alt="Search"/>
      <div class="wechat-container" @mouseenter="showQRCode = true" @mouseleave="showQRCode = false">
        <img style="width: 30px; margin-left: 35px" src="@/assets/imgs/01/info.png" alt="Info"/>
        <div v-if="showQRCode" class="qr-code">
          <img :src="qrcode" style="width: 100px;height: 100px" alt="QR Code"/>
        </div>
      </div>
    </div>
    <div v-if="currentSubRoutes && currentSubRoutes.length > 0" class="sub-route-box"
         @mouseenter="handleSubRouteMouseEnter" @mouseleave="handleSubRouteMouseLeave">
      <ul>
        <li v-for="subRoute in currentSubRoutes" :key="subRoute.name">
          <router-link v-if="subRoute.path" :to="subRoute.path">{{ subRoute.name }}</router-link>
          <span v-else @click="handleClick(subRoute)">{{ subRoute.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {get} from "@/utils/request";

export default {
  name: 'TopNav',
  created() {
    this.getSystem()
  },
  data() {
    return {
      list: [
        {name: '首 页', path: '/pc/index',},
        {name: '机 构', path: '/pc/organ'},
        {
          name: '政 策', path: '/pc/policy', subRoutes: [
            {name: '国家相关政策', path: '/pc/policy?class_id=1'},
            {name: '自治区相关政策', path: '/pc/policy?class_id=2'},
            {name: '市相关政策', path: '/pc/policy?class_id=3'},
          ]
        },
        {
          name: '资 讯', path: '/pc/consult',
        },
        {
          name: '清 单', path: '/pc/project', subRoutes: [
            {name: '非物质文化遗产代表性项目', path: '/pc/project'},
            {name: '保护基地和传统代表传承人', path: '/pc/inheritor'},
            {name: '保护基地和传统工艺工作站', path: '/pc/protect'},
          ]
        },
        {
          name: '资 源', path: '/pc/source', subRoutes: [
            {name: '展览', path: {path: '/pc/source-list', query: {type: 'video'}}},
            {name: '影音', path: {path: '/pc/source-list', query: {type: 'audio'}}},
            {name: '图集', path: {path: '/pc/source-list', query: {type: 'gallery'}}}
          ]
        },
        {
          name: '百 科', path: '/pc/wiki'
        }
      ],
      currentSubRoutes: [],
      isSubRouteBoxHovered: false,
      hoveredItem: null,
      leaveTimeout: null,
      showQRCode: false,
      qrcode: '',
      mainTitle: '哈密非物质文化遗产网',
      subTitle: '哈密非物质文化遗产中心'
    };
  },
  methods: {
    handleMouseEnter(item) {
      clearTimeout(this.leaveTimeout);
      this.hoveredItem = item;
      this.currentSubRoutes = item.subRoutes || [];
    },
    handleMouseLeave() {
      this.leaveTimeout = setTimeout(() => {
        if (!this.isSubRouteBoxHovered) {
          this.hoveredItem = null;
          this.currentSubRoutes = [];
        }
      }, 100);
    },
    handleSubRouteMouseEnter() {
      clearTimeout(this.leaveTimeout);
      this.isSubRouteBoxHovered = true;
    },
    handleSubRouteMouseLeave() {
      this.isSubRouteBoxHovered = false;
      this.currentSubRoutes = [];
    },
    getSystem() {
      get('/getSystem', {fields: 'top_qrcode,main_title,sub_title'}).then(res => {
         this.qrcode = res.data.top_qrcode
         this.mainTitle = res.data.main_title
         this.subTitle = res.data.sub_title
      }).catch(() => {
      })
    }
  }
};
</script>

<style scoped lang="scss">
.top {
  height: 147px;
  width: 100%;
  background: url(@/assets/imgs/01/top.png) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  position: relative;

  .top-p {
    font-size: 20px;
  }

  .co {
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    nav {
      margin-left: 125px;

      ul {
        display: flex;
        color: #cbb089;

        li {
          margin-right: 45px;
          font-size: 16px;
          position: relative;
        }
      }
    }
  }

  .wechat-container {
    position: relative;
    cursor: pointer;

    .qr-code {
      position: absolute;
      top: 40px;
      left: 0;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 9999;

    }
  }

  .sub-route-box {
    width: 100%;
    position: absolute;
    top: 66%;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      li {
        padding: 0 20px;
        white-space: nowrap;
        font-size: 20px;
        cursor: pointer;
        color: #cbb089;
      }

      li:hover {
        color: #f1b34d;
      }
    }
  }
}

a {
  text-decoration: none !important;
  color: #cbb089;
}

a:hover,
a.active {
  color: #f1b34d !important;
}
</style>
