import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const state = {

}
const actions = {

}
const mutations = {

}
const getters = {

}
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})