import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.css'
import router from './router/index'
import 'leaflet/dist/leaflet.css';
import $ from 'jquery';
Vue.prototype.$ = $;
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')